
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_OFFERS,
    ADMIN_OFFERS_CREATE,
    ADMIN_PRODUCT_CATEGORIES_CREATE,
    ADMIN_PRODUCTS_CREATE,
    ADMIN_PROMO_CODES_CREATE,
} from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import PageContent from 'Components/layout/panel/PageContent';
import OffersList from 'Components/admin/offers/List';
import ProductCategoriesList from 'Components/admin/productCategories/List';
import ProductsList from 'Components/admin/products/List';
import PromoCodesList from 'Components/admin/promoCodes/List';

export const TAB_OFFERS = 'offers';
export const TAB_PRODUCT_CATEGORIES = 'productCategories';
export const TAB_PRODUCTS = 'products';
export const TAB_PROMO_CODES = 'promoCodes';

export default class AdminOffers extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-offers"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_OFFERS.path),
                        label: 'Oferty',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Oferty"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === 'offers'),
                            key: 'createOffer',
                            label: 'Nowa oferta',
                            onClick: () => history.push(ADMIN_OFFERS_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === 'productCategories'),
                            key: 'createProductCategory',
                            label: 'Nowa kategoria produktów',
                            onClick: () => history.push(ADMIN_PRODUCT_CATEGORIES_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === 'products'),
                            key: 'createProduct',
                            label: 'Nowy produkt',
                            onClick: () => history.push(ADMIN_PRODUCTS_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === 'promoCodes'),
                            key: 'createPromoCode',
                            label: 'Nowy kod promocyjny',
                            onClick: () => history.push(ADMIN_PROMO_CODES_CREATE.path),
                        }]}
                        tabs={[{
                            key: TAB_OFFERS,
                            label: 'Lista ofert',
                            children: (
                                <OffersList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_PRODUCT_CATEGORIES,
                            label: 'Kategorie produktów',
                            children: (
                                <ProductCategoriesList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_PRODUCTS,
                            label: 'Produkty',
                            children: (
                                <ProductsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_PROMO_CODES,
                            label: 'Kody promocyjne',
                            children: (
                                <PromoCodesList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PRODUCT_VISIBILITY_HIDDEN, PRODUCT_VISIBILITY_SHOP_CREATOR } from 'Consts/products';
import { ADMIN_PRODUCTS_MANAGE } from 'Consts/routes';
import { API_RESOURCE_PRODUCT_CATEGORIES } from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { formatPrice } from 'Utils/math';
import { getDurationTypeLabel, getVisibilityLabel } from 'Utils/product';
import { translateTimeUnit } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelProductsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            listCategories: PropTypes.func.isRequired,
        }).isRequired,
        products: PropTypes.object.isRequired,
        onMapFilters: PropTypes.func,
    };
    static defaultProps = {
        onMapFilters: value => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        visibility: '',
        productCategory: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    getProductSpecializations = (element) => {
        return element?.specializations?.map(specialization => ({
            name: 'Specjalizacja',
            value: specialization?.name,
        })) || [];
    }

    render() {
        const { products, location, history, actions, onMapFilters } = this.props;

        return (
            <StyledComponent
                className="panel-products-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={products}
                    title="Lista produktów"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name}
                            subtitle={`${formatPrice(element.priceGross)}, ${element.vatPercent}% VAT`}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_PRODUCTS_MANAGE.path, { id: element.id }),
                            }]}
                            additionals={[{
                                name: 'Nazwa wyświetlana',
                                value: element.displayName,
                            }, {
                                name: 'Kategoria',
                                value: element.category && element.category.name || 'Brak',
                            }, {
                                name: 'Typ trwania',
                                value: getDurationTypeLabel(element).label,
                            }, {
                                name: 'Wartość trwania',
                                value: element.durationValue,
                            }, {
                                name: 'Specjalizacje',
                                value: element.specializations.map(specialization => specialization.name).join(', ') || 'Brak',
                            }, {
                                name: 'Widoczność',
                                value: getVisibilityLabel(element).label,
                            }, {
                                name: 'Wyświetlana wartość trwania',
                                value: element.displayDurationValue,
                            }, {
                                name: 'Typ wyświetlanej wartości trwania',
                                value: translateTimeUnit(element),
                            },
                            ...this.getProductSpecializations(element),
                            ]}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }, {
                        name: 'visibility',
                        label: 'Widoczność',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Wszystkie',
                                value: '',
                            },{
                                label: 'Widoczne w kreatorze',
                                value: PRODUCT_VISIBILITY_SHOP_CREATOR,
                            }, {
                                label: 'Ukryte',
                                value: PRODUCT_VISIBILITY_HIDDEN,
                            }],
                        },
                    }, {
                        name: 'productCategory',
                        label: 'Kategoria',
                        type: 'select',
                        inputProps: {
                            dynamic: true,
                            onLoad: query => actions.listCategories({ search: query, perPage: 999 }),
                            onMapResponse: response => response.payload[API_RESOURCE_PRODUCT_CATEGORIES].elements,
                            onMapOption: element => ({
                                value: element.id,
                                label: element.name,
                            }),
                        },
                    }])}
                />
            </StyledComponent>
        );
    }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFERS_MANAGE } from 'Consts/routes';
import { OFFER_PERIOD_TYPE_RECURRING } from 'Consts/offers';
import { API_RESOURCE_PRODUCT_CATEGORIES } from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getTypeLabel, getVisibilityLabel, getDurationTypeLabel, getPeriodTypeLabel } from 'Utils/offer';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelOffersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            listCategories: PropTypes.func.isRequired,
        }).isRequired,
        offers: PropTypes.object.isRequired,
        onMapFilters: PropTypes.func,
    };
    static defaultProps = {
        onMapFilters: value => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        suggestedForTrainer: '',
        enabled: true,
        productCategory: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { offers, location, history, onMapFilters, actions } = this.props;

        return (
            <StyledComponent
                className="panel-offers-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={offers}
                    title="Lista ofert"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name}
                            additionals={[{
                                name: 'Nazwa wyświetlana',
                                value: element.displayName,
                            }, {
                                name: 'Cena brutto',
                                value: formatPrice(element.totalPriceGross),
                            },  {
                                name: 'Typ',
                                value: getTypeLabel(element).label,
                            }, {
                                name: 'Cykliczność',
                                value: getPeriodTypeLabel(element).label,
                            }, {
                                name: 'Widoczność',
                                value: getVisibilityLabel(element).label,
                            }, {
                                visible: element.periodType === OFFER_PERIOD_TYPE_RECURRING,
                                name: 'Cykl płatności',
                                value: `${element.durationValue} (${getDurationTypeLabel(element).label})`,
                            }, {
                                visible: element.periodType === OFFER_PERIOD_TYPE_RECURRING,
                                name: 'Okres wypowiedzenia',
                                value: `${element.noticePeriodDurationValue} (${getDurationTypeLabel({ durationType: element.noticePeriodDurationType }).label})`,
                            }]}
                            disabled={!element.enabled}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: Boolean(element.isEditable),
                                to: withVariables(ADMIN_OFFERS_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }, {
                        name: 'suggestedForTrainer',
                        label: 'Sugerowane dla trenera',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Wszystkie',
                                value: '',
                            }, {
                                label: 'Tak',
                                value: 'true',
                            }, {
                                label: 'Nie',
                                value: 'false',
                            }],
                        },
                    }, {
                        name: 'enabled',
                        label: 'Włączone/Wyłączone',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Wszystkie',
                                value: undefined,
                            }, {
                                label: 'Włączone',
                                value: true,
                            }, {
                                label: 'Wyłączone',
                                value: false,
                            }],
                        },
                    }, {
                        name: 'productCategory',
                        label: 'Kategoria produktu',
                        type: 'select',
                        inputProps: {
                            dynamic: true,
                            onLoad: query => actions.listCategories({ search: query, perPage: 999 }),
                            onMapResponse: response => response.payload[API_RESOURCE_PRODUCT_CATEGORIES].elements,
                            onMapOption: element => ({
                                value: element.id,
                                label: element.name,
                            }),
                        },
                    }])}
                />
            </StyledComponent>
        );
    }
}

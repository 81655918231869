import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/productCategories/actions';

export default connect(
    state => ({
        productCategories: state.adminProductCategories.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    })
)(Component);

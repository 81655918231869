import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/products/actions';
import {
    list as listCategories,
} from 'Redux/modules/admin/productCategories/actions';

export default connect(
    state => ({
        products: state.adminProducts.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            listCategories: dispatch(listCategories),
        },
    })
)(Component);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_PROMO_CODES_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getLimitTypeLabel, getUseLimitTypeLabel, getValueTypeLabel } from 'Utils/promoCode';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelPromoCodesList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        promoCodes: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { promoCodes, location, history } = this.props;

        return (
            <StyledComponent
                className="panel-promo-codes-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={promoCodes}
                    title="Lista kodów promocyjnych"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.code}
                            disabled={!element.enabled}
                            
                            additionals={[{
                                name: 'Limit',
                                value: `${getLimitTypeLabel(element).label} ${element.limitValue || ''}`,
                            }, {
                                name: 'Limit per zamówienie',
                                value: `${getUseLimitTypeLabel(element).label} ${element.useLimitValue || ''}`,
                            }, {
                                name: 'Wartość',
                                value: `${getValueTypeLabel(element).label} (${element.value})`,
                            }, {
                                name: 'Ważny do',
                                value: element.validTo
                                    ? getFormattedDate(element.validTo, 'date')
                                    : 'Brak limitu',
                            }, {
                                name: 'Ilość powiązanych zamówień',
                                value: element.usesUserOfferCounter,
                            }, {
                                name: 'Ilość obniżonych transakcji',
                                value: element.usesTransactionCounter,
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_PROMO_CODES_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        }]}
                />
            </StyledComponent>
        );
    }
}

import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/promoCodes/actions';

export default connect(
    state => ({
        promoCodes: state.adminPromoCodes.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    })
)(Component);
